.layout-monaco-editor {
  width: 100%;

  > div.text-xs {
    height: var(--pad-m);
  }

  > div.textarea-code-editor {
    height: calc(100% - var(--pad-m));

    textarea {
      font-family: monospace;
    }

    .react-monaco-editor-container {
      border: 1px solid var(--t-color-gray-4);

      .monaco-editor.no-user-select.showUnused .overflow-guard {
        height: 100% !important;
      }
    }
  }

  &.force-editor {
    > div.text-xs {
      display: none;
    }

    > div.textarea-code-editor {
      height: 100%;
    }
  }
}
