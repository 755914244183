@import "../../commons/styles/index";

.page-list-ranking.layout-title .content-layout-title {
  h5 {
    margin-block-end: 0.5em;
  }
}

.page-list-ranking.layout-main .content-layout-main {
  //.ant-input-search {
  //  position: relative;
  //  left: 948px;
  //  top: 24.67%;
  //  width: 208px !important;
  //  height: 36px !important;
  //  background-color: white;
  //  border: 1px solid $color-gray-5;
  //  box-sizing: border-box;
  //  border-radius: 4px;
  //  @extend .text-m
  //}
  //.filter-button {
  //  position: relative;
  //  display: flex;
  //  flex-direction: row;
  //  padding: 16px;
  //  width: 114px;
  //  height: 36px;
  //  left: 960px;
  //}
  padding: var(--pad-m) 0;

  .content-problems {
    height: 100%;

    .ant-table {
      width: 100%;
      @extend .shadow;

      .ant-table-thead > tr > th {
        background: var(--t-color-gray-6);
        color: var(--t-color-gray-3);
        @extend .text-s;
        @extend .bold;
      }

      .ant-table-tbody > tr > td {
        .position-column {
          .color {
            border-radius: 50%;
            @extend .child-center;
            width: 30px;
            height: 30px;
            background: var(--color-green);
            border: 1px solid #E0E0E0;
            color: white;
          }
        }

        .nickname-column {
          img {
            width: 42px;
            border-radius: 100%;
          }

          .name {
            color: var(--t-color-gray-3);
            @extend .text-l
          }
        }

        .country-column {
          img {
            width: 42px;
            height: 42px;
          }
        }
      }
    }
  }
}

