@import "../../../commons/styles/index";

.layout-personal-profile {
  display: flex;
  justify-content: center;
  height: 100%;

  .content-profile-image {
    @extend .child-center;
    top: 0;
    width: calc((100% / 35) * 5);
    height: 100%;
    min-width: 120px;

    img {
      border-radius: 50%;
      width: 120px;
    }
  }

  .content-profile-data {
    width: calc((100% / 35) * 14);
    height: 100%;
    margin-left: calc(100% / 35);
    //color: var(--t-color-gray-1);

    .name-nickname-follow {
      @extend .child-center;

      .name-nickname {
        flex: 1;

        .nickname {
          margin: 0;
        }

        .name {
          color: var(--t-color-gray-3);
          @extend .text-m;
        }
      }

      button.follow-button {
        background-color: var(--t-color-primary-light);
        border-color: var(--t-color-primary-light);

        &:hover {
          background-color: var(--t-color-primary);
          border-color: var(--t-color-primary);
        }
      }
    }

    .nationality-institution-email-handles {
      max-width: 320px;
      border-top: 1px solid;
      border-color: var(--t-color-shadow);

      .nationality, .institution, .email, .handle {
        color: var(--t-color-gray-2);
        @extend .text-m;
        margin: var(--pad-t) 0 var(--pad-t) 0;

        svg, img {
          margin-right: var(--pad-t);
        }
      }
    }
  }
}
