.juki-layout-app.contest.create .juki-layout-app-main .juki-layout-sider,
.juki-layout-app.contest.edit .juki-layout-app-main .juki-layout-sider,
.juki-layout-app.problem.edit .juki-layout-app-main .juki-layout-sider,
.juki-layout-app.problem.create .juki-layout-app-main .juki-layout-sider {
  .juki-layout-sider-side-bar {
    .juki-layout-title .juki-layout-title-space {
      display: flex;
      
      a {
        margin: auto;
      }
    }
  }
  
  .juki-layout-sider-side-main {
    .juki-layout-title .juki-layout-title-space {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-content: center;
      
      > div {
        text-transform: capitalize;
        
        .juki-field-editable {
          margin-left: var(--pad-t);
        }
        
        .label-url {
          margin-left: var(--pad-t);
          color: var(--t-color-primary-light);
          
          .juki-field-editable {
            margin: 0;
          }
        }
      }
      
      > button {
        position: absolute;
        right: 0;
      }
    }
    
    .juki-layout-main .juki-layout-main-space {
      > div {
        margin: var(--pad-xt) 0;
        
        div[class*="-select "], div[class$="-select"], div.juki-field-editable {
          margin: 0 var(--pad-xt);
        }
      }
      
      .content-start-date, .content-end-date {
        .ant-picker.ant-picker-borderless {
          .ant-picker-input {
            @extend .juki-field-editable;
            
            .ant-picker-suffix, .ant-picker-clear {
              display: none;
            }
          }
        }
      }
      
      .content-clarifications, .content-registration, .content-scoreboard, .content-problem-time-restriction, .content-type-judge {
        > div:first-child {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}

.title-with-nav-tab > .juki-layout-title-space {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  
  .content-title {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @include sm {
      justify-content: space-around;
      position: relative;
      a {
        position: absolute;
        right: 0;
      }
    }
  }
  
  .juki-nav-tab-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    @include sm {
      flex-direction: column;
    }
  }
}
