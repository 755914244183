@import "src/styles/index";

.juki-table-layout {
  height: 100%;

  div[class$="-table-wrapper"] div[class$="-spin-nested-loading"] {
    div[class$="-spin-container"], div[class*="-spin-container "] {
      > div[class*="-table "] {
        background-color: transparent;
        @extend .shadow;
      
        border-radius: var(--pad-xt) var(--pad-xt) 0 0;
      
        > div[class$="-table-container"] {
          > div[class$="-table-header"] {
            border-radius: var(--pad-xt) var(--pad-xt) 0 0;
          
            > table > thead > tr > th {
              span[class*="-table-column-sorter "][class$="-table-column-sorter-full"] {
                display: none;
              }
            
              padding-top: 0;
              padding-bottom: 0;
              background-color: var(--t-color-gray-6);
              border: none;
            
              &:first-child {
                > div {
                  padding-left: var(--pad-lg);
                
                  @include md {
                    padding-left: var(--pad-md);
                  }
                
                  @include sm {
                    padding-left: var(--pad-sm);
                  }
                }
              }
            
              &:last-child {
                > div {
                  padding-right: var(--pad-lg);
                
                  @include md {
                    padding-right: var(--pad-md);
                  }
                
                  @include sm {
                    padding-right: var(--pad-sm);
                  }
                }
              }
            
              > div {
                @extend .text-s;
                @extend .bold;
              
                &:not([class$="-table-filter-column"]) {
                  @extend .child-center;
                  padding-right: 0;
                  padding-left: 0;
                }
              
                &[class$="-table-filter-column"] [class$="-table-filter-column-title"] {
                  padding-right: 0;
                  padding-left: 0;
                
                  > div {
                    @extend .child-center;
                  
                    &.cell-head-left {
                      justify-content: flex-start;
                    }
                  }
                }
              
                height: calc(var(--pad-t) + var(--pad-t) + var(--pad-m));
                background: var(--t-color-gray-6);
                color: var(--t-color-gray-2);
                text-transform: uppercase;
              }
            
              [class$="-table-filter-trigger-container"] [class$="-table-filter-trigger"] {
                [class$="icon-filter"] svg {
                  color: var(--t-color-gray-4);
                }
              
                &.active [class$="icon-filter"] svg {
                  color: var(--t-color-primary-light);
                }
              }
            }
          }
        
          > div[class$="-table-body"] {
            @extend .with-scroll;
          
            > table > tbody {
              > tr {
                &[class$="-table-measure-row"] td > div {
                  height: 0;
                  padding: 0;
                }
              
                > td {
                  padding: 0;
                  background-color: var(--t-color-lightest);
                  border: none;
                  border-bottom: 1px solid var(--t-color-gray-5);
                  overflow-wrap: anywhere;
                
                  > div {
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    padding: var(--pad-t) var(--pad-xt);
                  
                    @include sm {
                      padding: 6px 4px;
                    }
                  }
                
                  &:first-child {
                    > div {
                      padding-left: var(--pad-lg);
                    
                      @include md {
                        padding-left: var(--pad-md);
                      }
                    
                      @include sm {
                        padding-left: var(--pad-sm);
                      }
                    }
                  }
                
                  &:last-child {
                    > div {
                      padding-right: var(--pad-lg);
                    
                      @include md {
                        padding-right: var(--pad-md);
                      }
                    
                      @include sm {
                        padding-right: var(--pad-sm);
                      }
                    }
                  }
                
                  > div.cell-body-who .user-photo {
                    color: var(--t-color-gray-2);
                  
                    img {
                      width: 42px;
                      height: 42px;
                      margin-right: var(--pad-xt);
                      border-radius: 50%;
                    }
                  }
                
                  > div.cell-body-user-position {
                    padding: 21px 8px 21px 16px !important;
                  
                    .text-m.semi-bold {
                      width: 30px;
                      height: 30px;
                      padding: 3px 0;
                      background-color: var(--color-red-dark);
                      border-radius: 50%;
                      color: var(--t-color-lightest);
                      text-align: center;
                    }
                  
                    &.no-points .text-m.semi-bold {
                      background-color: var(--t-color-gray-5) !important;
                    }
                  }
                
                  > div.cell-body-user-name .text-l {
                    height: 48px;
                    color: var(--color-gray-3);
                  }
                
                  > div.cell-body-user-points {
                    flex-direction: column;
                  
                    .text-s {
                      color: var(--color-gray-3);
                    }
                  }
                
                  > div.cell-body-user-photo img {
                    width: 42px;
                    height: 42px;
                    border-radius: 50%;
                  }
                
                  > div .constant-text {
                    margin-right: var(--pad-xt);
                  }
                
                  > div.cell-body-when {
                    flex-direction: column;
                  }
                
                  > div.cell-body-center {
                    justify-content: center;
                  }
                
                  > div.cell-body-left {
                    justify-content: flex-start;
                  }
                
                  > div.cell-body-link {
                    color: var(--color-info-dark);
                  
                    > * {
                      cursor: pointer;
                    }
                  
                    span.cell-body-title-name-index {
                      padding: 4px;
                      margin-right: var(--pad-xt);
                      background-color: var(--t-color-gray-6);
                      border-radius: var(--border-radius-lg);
                    }
                  }
                
                  > div.cell-body-verdict {
                    .verdict-tag-layout {
                      padding: 2px var(--pad-xt);
                      border-radius: var(--pad-m);
                      line-height: 28px !important;
                    
                      &.AC {
                        background-color: var(--color-green);
                      }
                    
                      &.PA {
                        background-color: var(--color-green-light);
                      
                        .verdict-tag-points {
                          line-height: 28px !important; // check important
                        }
                      }
                    
                      &.CE, &.RTE, &.TLE, &.MLE, &.WA {
                        background-color: var(--color-red-light);
                      }
                    
                      &.PENDING {
                        background-color: var(--color-info-light);
                      }
                    }
                  }
                
                  > div.cell-body-user-attempt {
                    padding: 12px 8px;
                  
                    .balloon {
                      width: 20px;
                      height: 48px;
                    
                      svg {
                        width: 20px;
                        height: 48px;
                      }
                    }
                  
                    .text-xs {
                      color: var(--color-gray-3);
                    }
                  }
                }
              }
            
              > tr[data-row-key^="place(1)"] td div.cell-body-user-position div.text-m.semi-bold {
                background-color: var(--t-color-primary);
              }
            
              > tr[data-row-key^="place(2)"] td div.cell-body-user-position div.text-m.semi-bold {
                background-color: var(--t-color-secondary);
              }
            
              > tr[data-row-key^="place(3)"] td div.cell-body-user-position div.text-m.semi-bold {
                background-color: var(--color-green);
              }
            }
          }
        }
      }
    
      > ul[class*="-table-pagination "] {
        width: max-content;
        height: var(--pad-h);
        margin: var(--pad-t) auto;
      }
    }
  }
}
