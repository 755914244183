:root {
  /* ********************************          GLOBAL SIZES          ******************************** */
  --screen-sm-min: 320px; /* Phones [320, 768] */
  --screen-md-min: 768px; /* Tablets [768, 1120] */
  --screen-lg-min: 1248px; /* PC [1248, INF] 1120px + var(--pad-xH) * 2 */
  
  --height-app-min: 520px;
  --width-app-min: 320px;
  --width-app-body: 1120px;
  --width-app-padding: calc((100% - var(--width-app-body)) / 2);
  --width-app-padding-lg: var(--pad-H);
  --width-app-padding-md: var(--pad-h);
  --width-app-padding-sm: var(--pad-m);
  --height-app-header: 64px;
  --height-app-header-md: 64px;
  --height-app-header-sm: 64px;
  --height-app-footer: 64px;
  --height-app-footer-md: 64px;
  --height-app-footer-sm: 114px;
  --height-app-body: calc(100% - var(--height-app-header));
  --height-app-body-mobile: calc(100% - var(--height-app-header-mobile) - var(--height-app-footer-mobile));
  --height-home-layout-header: 200px;
  --height-home-layout-header-md: 240px;
  --height-home-layout-header-sm: 240px;
  
  --height-layout-title: 128px; // 190px
  --height-layout-title-mobile: 140px;
  --width-layout-side-bar: 256px;
  --width-layout-side-bar-mobile: 192px;
  --width-layout-side-bar-close: 48px;
  --width-layout-side-main: calc(100% - var(--width-layout-side-bar));
  --width-layout-side-main-mobile: calc(100% - var(--width-layout-side-bar-mobile));
  --width-layout-side-main-with-side-bar-close: calc(100% - var(--width-layout-side-bar-close));
  --width-layout-side-right-bar: 320px;
  --width-layout-side-right-bar-close: 64px;
  --width-layout-side-right-main: calc(100% - var(--width-layout-side-right-bar));
  --height-layout-footer: 72px;
  --height-layout-pagination: 64px;
  
  --pad-lg: 32px;
  --pad-md: 24px;
  --pad-sm: 16px;
  
  --pad-xH: 56px;
  --pad-H: 52px;
  --pad-xL: 48px;
  --pad-L: 44px;
  --pad-xh: 40px;
  --pad-h: 36px;
  --pad-xl: 32px;
  --pad-l: 28px;
  --pad-m: 24px;
  --pad-s: 20px;
  --pad-xs: 16px;
  --pad-t: 12px;
  --pad-xt: 8px;
  
  --border-radius-lg: 8px;
  --border-radius-md: 4px;
  --border-radius-sm: 2px;
  /* ********************************          GLOBAL COLORS         ******************************** */
  --color-navbar: white;
  
  --color-info-dark: #2466B3;
  --color-info: #2F86EB;
  --color-info-light: #79B6FD;
  
  --color-green-dark: #319E63;
  --color-green: #43D787;
  --color-green-light: #4EFA9D;
  
  --color-red-dark: #B84444;
  --color-red: #EB5757;
  --color-red-light: #FE9F9F;
}
