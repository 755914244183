@import "../../../../commons/styles/index";

.modal-edit-clarification {
  width: 448px !important;

  .ant-modal-content {
    padding: var(--pad-xl);

    .ant-modal-body {
      flex-direction: column;

      .modal-clarification-box {
        width: 100%;

        > .text-xl.semi-bold {
          text-transform: capitalize;
        }

        > .modal-clarification-item {
          margin-bottom: var(--pad-s);

          > .label-item {
            text-transform: capitalize;
          }

          > textarea {
            background-color: transparent;
          }

          .ant-radio-group.ant-radio-group-outline {
            span {
              text-transform: capitalize;
            }
          }
        }
      }

      .modal-clarification-buttons {
        width: 100%;
        text-align: right;

        button {
          //margin-left: var(--pad-t);
        }
      }
    }
  }
}
