@import "src/styles";

.users-table-layout {
  height: 100%;
  
  tbody.ant-table-tbody {
    tr[data-row-key$="ARCHIVED"], tr[data-row-key$="REPORTED"] {
      color: var(--color-red-dark);
    }
    
    tr {
      td.ant-table-cell {
        > div {
          .permissions-cell {
            .permissions-box {
              .permission {
                display: flex;
                width: 170px;
                
                .label-permission {
                  @extend .text-s;
                  @extend .semi-bold;
                  width: 100px;
                  color: var(--t-color-gray-2);
                }
                
                input.ant-input {
                  width: var(--pad-xs);
                  padding: 0;
                  margin: 0;
                  text-align: center;
                }
              }
            }
            
            @extend .child-center;
          }
        }
      }
    }
  }
}
