@import "../../../commons/styles/index";

.layout-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .content-head {
    margin: 0 0 var(--pad-xt) 0;
  }

  .content-title, .content-info {
    margin: var(--pad-xt) 0;
  }

  .content-action {
    margin: var(--pad-xt) 0 0 0;
  }

  .content-title {
    .text-l.bold {
      max-height: inherit;
      color: var(--t-color-primary);
      text-align: center;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
