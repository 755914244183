@import "../../styles/index";

.ant-modal.modal-login {
  width: 950px !important;
  @include sm {
    width: calc(#{$screen-sm-min} - var(--pad-xt) - var(--pad-xt)) !important;
  }
  @include md {
    width: calc(#{$screen-md-min} - var(--pad-xh) - var(--pad-xh)) !important;
  }
  
  .ant-modal-content {
    .ant-modal-body {
      //height: 620px; // 282 (content) + 56(header) + 56 (footer) + 226 (extra padding)
      height: 520px; // 282 (content) + 56(header) + 56 (footer) + 226 (extra padding)
      @include md {
        //height: 520px; // 282 (content) + 56(header) + 56 (footer) + 106 (extra padding)
        height: 420px; // 282 (content) + 56(header) + 56 (footer) + 106 (extra padding)
      }
      @include sm {
        height: 394px; // 282 (content) + 56(header) + 56 (footer)
      }
      
      .layout-left {
        div::first-letter {
          text-transform: uppercase;
        }
        
        div.text-xh { // check
          text-transform: capitalize;
        }
        
        img {
          max-width: 100%;
          max-height: 100%;
          @include lg {
            height: 250px;
          }
        }
      }
      
      .layout-right {
        .ant-form {
          .ant-form-item-control-input-content {
            justify-content: space-between !important;
            
            .link {
              position: absolute;
              top: -32px;
              right: 0;
              color: var(--color-info);
            }
            
            .sign-up-link, .forgot-password-link {
              margin-right: 10px;
              cursor: pointer;
              color: var(--color-info);
            }
            
            .forgot-password-link {
              position: absolute;
              right: 0;
              bottom: 30px;
            }
          }
          
          .login-content {
            margin: 0;
            
            .ant-form-item-control-input-content {
              @extend .child-center;
            }
          }
        }
      }
    }
  }
}

.modal-forgot-password {
  width: 800px !important;
  @include md {
    width: calc(#{$screen-md-min} - var(--pad-xH) - var(--pad-xH) - var(--pad-xH) - var(--pad-xH)) !important;
  }
  @include sm {
    width: calc(#{$screen-sm-min} - var(--pad-l) - var(--pad-l)) !important;
  }
  
  p:first-letter {
    text-transform: uppercase;
  }
  
  .ant-modal-content {
    .ant-modal-body {
      height: 280px; // 282 (content) + 56(header) + 56 (footer) + 226 (extra padding)
      padding: 56px var(--pad-xh) !important;
      @include md {
        height: 300px; // 188 (content) + 56(header) + 56 (footer)
        padding: 56px var(--pad-h) !important;
      }
      @include sm {
        height: 402px; // 290 (content) + 56(header) + 56 (footer)
        padding: 56px var(--pad-s) !important;
      }
      display: block !important;
      
      .ant-form.ant-form-vertical {
        margin-top: var(--pad-xt);
        
        .juki-field-editable {
          width: 100%;
          
          input {
            width: 100%;
          }
        }
        
        .ant-form-item-control-input-content {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}
