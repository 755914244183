@import "/src/styles/index";

.test-cases-layout {
  > .content-problem-mode-save {
    display: flex;

    > div:first-child {
      flex: 1;
    }
  }

  > .content-group-point {
    @extend .with-scroll;
    display: flex;
    padding: var(--pad-s) 0;

    > .group-point-box:first-child {
      @extend .text-m;
      @extend .semi-bold;
    }

    > .group-point-box {
      .group-point-group, .group-point-point {
        > .juki-field-editable {
          > input {
            width: 50px;
          }
        }
      }

    }
  }

  > .test-cases-box {
    padding: var(--pad-s) 0;

    > .test-case-list-box {
      min-width: min-content;

      > .item-test-case {
        display: flex;
        padding: var(--pad-s) 0;
        border-bottom: 1px solid;
        border-color: var(--t-color-shadow);

        > div {
          padding: 0 var(--pad-t);
        }

        > div:first-child {
          @extend .text-m;
          @extend .bold;
          width: 100px;
          color: var(--t-color-gray-3);
        }

        > div.test-case-group {
          width: 50px;
        }

        > div {
          @extend .child-center;

          &.test-case-input, &.test-case-output {
            flex: 1;
            max-width: 250px;

            > div {
              max-width: 100%;
            }
          }
        }
      }
    }
  }
}

.ant-modal.layout-test-case-editor {
  > .ant-modal-content {
    padding: var(--pad-h);

    > .ant-modal-body {
      > .ant-tabs.ant-tabs-top {
        width: 100%;

        > .ant-tabs-nav {
          width: min-content;

          > .ant-tabs-nav-wrap {
            > .ant-tabs-nav-list {
              > .ant-tabs-tab {
                padding: var(--pad-xt) var(--pad-m);
              }
            }
          }
        }

        > .ant-tabs-content-holder {
          > .ant-tabs-content.ant-tabs-content-top {
            > .ant-tabs-tabpane.ant-tabs-tabpane-active {
              @extend .child-center;
              flex-direction: column;
              width: 100%;
              height: 100%;
              min-height: 300px;

              > .group-box {
                margin: var(--pad-t);

                > .text-m.bold {
                  margin-right: var(--pad-s);
                }
              }

              > textarea.ant-input {
                font-family: monospace;
              }

              > .test-case-box {
                @extend .with-scroll;
                border-radius: var(--border-radius-md);
                width: 100%;
                padding: var(--pad-t);
                border: 1px solid;
                border-color: var(--t-color-shadow);

                > span {
                  background-color: rgba(0, 0, 0, 0.05);
                  font-family: monospace;
                  white-space: pre;
                }
              }

              button {
                margin-top: var(--pad-m);
              }
            }
          }
        }
      }
    }
  }
}
