.juki-layout-app.problems > .juki-layout-app-main {
  > .juki-layout-main > .juki-layout-main-space > .content-problems {
    height: 100%;
    
    .cell-body-tags {
      flex-wrap: wrap;
      justify-content: flex-start !important;
      
      .tag-column {
        max-width: 100%;
        box-sizing: border-box;
        border: 1px solid;
        border-color: var(--t-color-gray-3);
        border-radius: var(--pad-m);
        opacity: 0.7;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}
