@import "../../../../commons/styles/index";

.juki-layout-app.contest.create, .juki-layout-app.contest.edit {
  .juki-layout-app-main .juki-layout-sider {
    .juki-layout-sider-side-main {
      .juki-layout-main .juki-layout-main-space {
        .content-create-contest-problems-table {
          width: 100%;
          background-color: var(--t-color-lightest);
          border-radius: var(--pad-xt) var(--pad-xt) 0 0;

          .add-contest-table-problems-row-title, .add-contest-table-problems-row {
            display: flex;
            flex-direction: row;
            border-radius: var(--pad-xt) var(--pad-xt) 0 0;

            > div {
              @extend .child-center;
            }

            .cell-drag {
              padding: 9px 5px;

              svg {
                color: var(--t-color-gray-1);
              }
            }

            .cell-number {
              width: 50px;
            }

            .cell-name {
              flex: 1;
            }

            .cell-color {
              width: 40px;
              letter-spacing: -1px;
            }

            .cell-points {
              width: 70px;
              border-radius: var(--pad-xt) var(--pad-xt) 0 0;
            }

            .cell-judge {
              width: 100px;
            }

            .cell-start, .cell-duration {
              width: 120px;
            }

            .cell-delete {
              width: var(--pad-h);

              button {
                border: none;
              }
            }
          }

          .add-contest-table-problems-row-title {
            height: var(--pad-xH);
            background-color: var(--t-color-gray-6);
            border-radius: var(--pad-xt) var(--pad-xt) 0 0;
            color: var(--t-color-gray-3);
            text-transform: uppercase;
          }

          .add-contest-table-problems-row {
            border-top: 1px solid;
            border-color: var(--t-color-gray-5);
          }
        }
      }
    }
  }
}



