@import "../../commons/styles/index";

.juki-layout-app.home .juki-layout-app-main {
  .juki-layout-title {
    height: var(--height-home-layout-header);
    @include md {
      height: var(--height-home-layout-header-md);
    }
    @include sm {
      height: var(--height-home-layout-header-sm);
    }
    
    .juki-layout-title-space .content {
      height: 100%;
      
      > div:last-child {
        height: 100%;
        
        img {
          width: 100%;
          height: 100%;
          padding-top: var(--pad-s);
        }
      }
    }
  }
  
  .juki-layout-main .juki-layout-main-space {
    margin-top: 0;
    margin-bottom: 0;
    
    .content {
      height: calc(100vh - var(--height-app-header) - var(--height-home-layout-header) - var(--height-app-footer));
      @include md {
        height: calc(100vh - var(--height-app-header-md) - var(--height-home-layout-header-md) - var(--height-app-footer-md));
      }
      @include sm {
        display: block;
        height: 1000px;
      }
      $content-live-contests-height: 384px;
      
      > div {
        height: 100%;
        
        &:first-child {
          min-height: 300px;
          
          @include sm {
            height: calc(100% - #{$content-live-contests-height}); // height of cards
          }
        }
        
        &:nth-child(2) {
          @include sm {
            height: $content-live-contests-height;
          }
        }
        
        .title-content {
          padding: var(--pad-xs) 0;
          text-transform: capitalize;
          
          .juki-loader {
            display: inline-flex;
            width: var(--pad-s);
            height: var(--pad-s);
          }
        }
        
        .ant-carousel, .content-live-contests {
          height: calc(100% - 56px - var(--pad-xL));
        }
        
        .ant-carousel {
          .slick-slider {
            height: 100%;
            
            .slick-list {
              height: 100%;
              background-color: var(--t-color-lightest);
              border-radius: 10px;
              overflow: hidden auto;
              
              .slick-track {
                height: 100%;
                
                .slick-slide {
                  position: relative;
                }
              }
            }
          }
          
          .slick-dots {
            bottom: -48px;
            display: none;
            height: 48px;
            padding: var(--pad-t);
            margin-bottom: 0;
            
            li {
              width: var(--pad-s);
              height: var(--pad-s);
              
              button {
                height: 100%;
                background-color: var(--t-color-gray-6);
                border: 2px solid;
                border-color: var(--t-color-gray-3);
                border-radius: 50%;
              }
            }
            
            li.slick-active button {
              background-color: var(--t-color-gray-4);
            }
          }
        }
        
        .content-live-contests {
          display: flex;
          flex-wrap: wrap;
          @include sm {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
          }
          
          .layout-card {
            width: 100%;
            @include sm {
              width: 200px;
              min-width: 200px;
            }
          }
          
          .layout-card + .layout-card {
            margin-top: var(--pad-xs);
            @include sm {
              margin-top: 0;
              margin-left: var(--pad-xs);
            }
          }
          
          > .text-l.bold {
            width: 100%;
            margin: auto;
            text-align: center;
            word-break: break-all;
            
            &:first-letter {
              text-transform: uppercase;
            }
          }
        }
        
        .content-more {
          height: var(--pad-xL);
        }
      }
    }
  }
}

.modal-welcome {
  width: 832px !important;
  
  div[class$="-modal-body"] {
    padding: var(--pad-lg) !important;
    
    .given-name {
      text-transform: capitalize;
    }
    
    img {
      height: 192px;
    }
    
    p {
      margin: var(--pad-t) 0 var(--pad-s) 0;
    }
    
    button {
      margin-right: var(--pad-t);
    }
  }
}
