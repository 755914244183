@import "../../../commons/styles/index";

.layout-pagination {
  width: 100%;
  height: var(--pad-h);
  margin: calc(var(--pad-l) / 2);

  .content-pagination-main {
    position: relative;
    width: max-content;
    height: var(--pad-h);
    padding: 0 174px 0 44px;

    ul.ant-pagination {
      li.ant-pagination-prev {
        position: absolute;
        left: 0;
      }

      li.ant-pagination-next {
        position: absolute;
        right: 130px;
      }
    }
  }
}

// To aply all paginations
ul.ant-pagination {
  @extend .shadow;
  margin: 0;
  background-color: var(--t-color-lightest);
  border-radius: 20px;

  li.ant-pagination-prev, li.ant-pagination-next {
    width: var(--pad-h);
    height: var(--pad-h);
    margin: 0;

    .ant-pagination-item-link {
      @extend .shadow;
      @extend .child-center;
      border-radius: 50%;
      padding: 9px;
      background-color: var(--t-color-lightest);

      .anticon {
        svg {
          width: var(--pad-xs);
          height: var(--pad-xs);
          color: var(--t-color-gray-1);
        }
      }
    }

    &.ant-pagination-disabled {
      opacity: 0.4;
    }
  }

  li.ant-pagination-item-active {
    border-radius: 50%;
    background-color: var(--t-color-gray-5);

    a {
      @extend .text-m;
      @extend .bold;
    }
  }

  li.ant-pagination-item {
    width: var(--pad-h);
    height: var(--pad-h);
    margin: 0;
    @extend .text-m;
    padding: 6px 0;
    background-color: transparent;
    border: none;

    /*a {
      color: var(--t-color-gray-1);
    }*/

    &:focus, &:hover {
      a {
        @extend .text-m;
        @extend .bold;
      }
    }
  }

  li.ant-pagination-jump-prev, li.ant-pagination-jump-next {
    &:hover {
      .ant-pagination-item-container {
        padding-top: 2px;

        svg {
          width: var(--pad-xs);
          height: var(--pad-xs);
          color: var(--t-color-gray-1);
        }
      }
    }
  }

  .ant-pagination-options {
    @extend .child-center;
    position: absolute;
    top: 0;
    right: 0;
    width: 130px;
    height: var(--pad-h);
    margin: 0;

    .ant-pagination-options-size-changer {
      @extend .text-m;
    }

    .ant-pagination-options-quick-jumper {
      @extend .text-m;

      input {
        @extend .text-m;
        margin-right: 0;
      }
    }
  }
}
