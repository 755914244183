.layout-upload-image {
  width: 100%;
  padding: var(--pad-m);
  
  .result-box {
    .text-xs.semi-bold {
      color: var(--t-color-primary-light);
    }
  }
}
