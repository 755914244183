@import "src/styles/index";

.juki-layout-app .juki-layout-app-header {
  height: auto;
  position: fixed;
  top: 0;
  z-index: 1;
  width: 100%;
  padding: 0 calc(var(--pad-xl) * 2);
  @include md {
    padding: 0 var(--width-app-padding-md);
  }
  @include sm {
    padding: 0 var(--width-app-padding-sm);
  }
  
  .logo {
    float: left;
    width: 100px;
    height: var(--height-app-header);
    margin-right: var(--pad-lg);
    
    a svg {
      width: 100%;
      height: var(--height-app-header);
      padding: var(--pad-xt) 0;
    }
  }
  
  .juki-header-menu {
    float: left;
    @include sm {
      display: none;
    }
    border: none;
    max-width: calc(100% - 264px - 72px - 100px);
    
    li[class$="-menu-item"], li[class*="-menu-item "] {
      height: var(--height-app-header);
      top: 0;
      padding: 0 var(--pad-t);
      border: none;
      text-align: center;
      text-transform: uppercase;
      max-width: 128px;
      line-height: normal !important;
      @include md {
        max-width: 120px;
        padding: 0 var(--pad-xt);
      }
      
      a {
        height: 100%;
        display: flex;
        align-items: center;
        white-space: pre-wrap;
        word-break: break-word;
      }
    }
    
    li[class$="-menu-item-selected"], li[class*="-menu-item-selected "] {
      font-weight: 700;
    }
    
    .ant-menu-item-selected:after, .ant-menu-item:not(.item-language-selector):hover:after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: var(--pad-xt);
      background: var(--t-color-secondary);
      border-radius: 4px 4px 0 0;
      content: " ";
    }
  }
  
  .content-user {
    float: right;
    align-items: center;
    display: flex;
    height: var(--height-app-header);
    
    .juki-loader {
      justify-content: flex-end;
      
      .ant-spin.ant-spin-spinning {
        justify-content: flex-end;
        
        span {
          color: var(--t-color-lightest);
        }
      }
    }
    
    > button {
      span {
        max-width: 100px;
      }
    }
    
    &.logged {
      @extend .content-user;
      display: flex;
      padding: var(--pad-xt) 0;
      
      .profile-image {
        
        display: flex;
        width: 48px;
        height: 48px;
        
        img {
          width: 48px;
          height: 48px;
          border: 2px solid;
          border-radius: 50%;
          color: var(--color-navbar);
        }
      }
      
      .text-m.semi-bold {
        position: relative;
        padding: var(--pad-t);
        cursor: pointer;
        color: var(--color-navbar);
        
        svg {
          margin-left: var(--pad-t);
        }
        
        &:hover {
          text-decoration-line: underline;
        }
        
        &.on-my-profile:after {
          position: absolute;
          bottom: -7px;
          left: 5px;
          width: calc(100% - 10px);
          height: 6px;
          background: var(--t-color-secondary);
          border-radius: 4px 4px 0 0;
          content: " ";
        }
      }
    }
  }
}

.dropdown-user-profile {
  width: 160px !important;
}
