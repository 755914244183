@import "../../../../commons/styles/index";

.ant-notification.ant-notification-topRight {
  .ant-notification-notice.ant-notification-notice-closable {
    padding: var(--pad-xs) var(--pad-m);

    .ant-notification-notice-content {
      .ant-notification-notice-with-icon {
        color: white;

        .anticon {
          svg {
            height: var(--pad-xl);
            width: var(--pad-xl);
          }
        }

        .ant-notification-notice-message {
          @extend .text-xl; // check
          @extend .bold; // check
          margin-left: 60px;
        }

        .ant-notification-notice-description {
          @extend .text-m;
          color: white;
          margin-left: 60px;
        }

        .ant-notification-notice-icon {
          padding: 10px 0;
          @extend .child-center;
        }

        .ant-notification-notice-message {
          color: white;
        }
      }
    }

    .ant-notification-notice-close {
      @extend .child-center;
      height: calc(100% - var(--pad-xs) - var(--pad-xs));
      top: var(--pad-xs);

      .ant-notification-close-x {
        .anticon.anticon-close.ant-notification-close-icon {
          svg {
            height: 16px;
            width: 16px;
            color: white;
          }
        }
      }
    }
  }

  .ant-notification-notice.success-notification {
    background-color: var(--color-green);
  }

  .ant-notification-notice.info-notification {
    background-color: var(--color-info);
  }

  .ant-notification-notice.warning-notification {
    background-color: #F2C94C; // TODO: Check this color
  }

  .ant-notification-notice.error-notification {
    background-color: var(--color-red);
  }
}
