@import "../../../../commons/styles/index";

.juki-layout-app.problem.view.statement {
  
  .problem-head-box {
    position: relative;
    
    .index {
      left: 0;
      position: absolute;
      padding: 0 10px;
      background-color: var(--t-color-gray-5);
      border-radius: 4px;
    }
    
    .title {
      width: calc(100% - 100px);
      text-align: center;
    }
  }
  
  .juki-card + .juki-card, .juki-card + button, .juki-card + a {
    display: block;
    margin-top: var(--pad-lg);
    @include md {
      margin-top: var(--pad-md);
    }
    @include sm {
      margin-top: var(--pad-sm);
    }
  }
  
  .problem-limits {
    p {
      display: flex;
      flex-wrap: wrap;
      overflow-wrap: anywhere;
      
      .text-l.bold, .text-m.semi-bold {
        padding-right: var(--pad-xt);
        text-transform: capitalize;
      }
    }
  }
  
  .problem-submit {
    .drag-file {
      margin: auto;
      width: 100%;
      
      p:first-letter {
        text-transform: uppercase;
      }
      
      .ant-upload.ant-upload-drag {
        .ant-upload.ant-upload-btn {
          @extend .child-center;
          height: 100%;
          padding: 0;
          box-sizing: border-box;
          background-color: var(--t-color-gray-6);
          border: 1px dashed;
          border-color: var(--t-color-gray-3);
          //color: var(--t-color-gray-1);
          
          .anticon.anticon-cloud-upload svg {
            width: 48px;
            height: 48px;
            color: var(--t-color-gray-4);
          }
          
          .text-s.bold {
            color: var(--t-color-gray-3);
            text-transform: uppercase;
          }
        }
      }
    }
  }
}
