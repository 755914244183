@import "../../../commons/styles/index";

.juki-layout-app.contests .juki-layout-app-main {
  .juki-layout-main .juki-layout-main-space {
    .content > div {

      @include lg {
        &:nth-child(4n + 1) {
          margin-left: 0;
        }
      }

      @include md {
        &:nth-child(3n + 1) {
          margin-left: 0;
        }
      }

      @include sm {
        &:nth-child(2n + 1) {
          margin-left: 0;
        }
      }

      .layout-card {
        height: 264px;

        .content-head .users-badge {
          position: relative;
          width: calc(var(--pad-h) * 2);
          height: 24px;
          padding: 0 16px;
          background: #E0E0E0;
          border-radius: 4px;

          .anticon-user {
            padding: 5px 2px;
          }

          .text-s.bold {
            padding: 2px 2px 2px 0;
          }

          .anticon.anticon-check {
            position: absolute;
            top: 0;
            right: -10px;
            padding: 2px;
            background-color: var(--color-green-dark);
            border: 2px solid white;
            border-radius: 50%;
            color: white;

            svg {
              width: 12px;
              height: 12px;
            }
          }
        }

        .content-title {
          height: 72px;
          max-height: 72px;
        }
      }
    }

    .content-past-contests {
      height: 100%;
    }
  }
}
