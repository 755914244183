.load-crop-image {
  width: 100%;
  
  .crop-preview-box {
    margin: var(--pad-t) 0;
    
    .crop-box {
      width: 100%;
      height: 448px;
      overflow: auto;
      
      > div {
        width: max-content;
      }
    }
    
    .preview-box {
      padding: var(--pad-s);
      
      img {
        height: 192px !important;
      }
    }
  }
  
  .actions-box {
    margin: var(--pad-t) 0;
  }
}
