@import "src/styles/index";

.ant-modal.modal-code {
  width: 950px !important;
  height: calc(100% - var(--height-app-header) - var(--height-app-footer) - var(--pad-md));
  @include md {
    width: calc(var(--screen-md-min) - var(--pad-xH) - var(--pad-xh)) !important;
  }
  @include sm {
    width: calc(100% - var(--pad-xt) - var(--pad-xt)) !important;
  }
  
  .ant-modal-content {
    .ant-modal-body {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 0;
      
      .content-description {
        display: flex;
        width: 100%;
        padding: var(--pad-t) var(--pad-xH) 0 var(--pad-xH);
        justify-content: center;
        
        > div {
          flex: 1;
          display: flex;
          flex-direction: column;
          
          .display-label {
            margin: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0 var(--pad-t);
            
            span[class$="-tag"], span[class*="-tag "] {
              border-radius: var(--pad-m);
              color: inherit;
              text-transform: uppercase;
            }
            
            .programming-language-selector {
              width: 200px !important;
              
              > div {
                //width: 200px;
              }
            }
          }
          
          > div {
            display: flex;
            
            label {
              text-transform: uppercase;
              line-height: var(--pad-t);
            }
          }
        }
      }
      
      .content-monaco-editor {
        width: 100%;
        flex: 1;
        padding: 0 var(--pad-m) var(--pad-m) var(--pad-m);
        
        .react-monaco-editor-container {
          width: 100%;
          height: 100%;
          
          .monaco-editor.no-user-select.showUnused {
            width: 100% !important;
            height: 100% !important;
            
            .overflow-guard {
              width: 100% !important;
              height: 100% !important;
            }
          }
        }
      }
    }
  }
}

.ant-modal-confirm-content, .ant-modal-confirm-title {
  &:first-letter {
    text-transform: uppercase;
  }
}
