@import "fonts";
@import "break-points";
@import "variables";

/*
 ANT BUTTON
 */
button[class*="-btn "], button[class$="-btn"] {
  height: auto !important;
  min-height: var(--pad-xl);
  text-transform: uppercase !important;
  transition: none;
  
  &:hover, &:focus {
    background-color: var(--t-color-primary-light) !important;
    border-color: var(--t-color-primary-light) !important;
    color: var(--t-color-lightest) !important;
  }
  
  &[class$="-btn-primary"], &[class*="-btn-primary "] {
    &:hover, &:focus {
      background-color: var(--t-color-secondary-dark) !important;
      border-color: var(--t-color-secondary-dark) !important;
      color: var(--t-color-lightest) !important;
    }
  }
  
  &[class$="-btn-text"], &[class*="-btn-text "] {
    color: inherit;
    
    &:hover, &:focus {
      background-color: transparent !important;
      border-color: transparent !important;
      color: inherit !important;
      
      span {
        text-decoration: underline !important;
      }
    }
  }
  
  > span {
    white-space: normal;
  }
  
  &.ant-btn-icon-only {
    padding: 2px;
  }
  
  &:disabled {
    opacity: 0.4;
  }
  
  &.ant-btn-ghost {
    border: 2px solid var(--color-primary);
  }
}

/* ANT RADIO BUTTON */
span[class$="-radio"], span[class*="-radio "] {
  span[class$="-radio-inner"] {
    width: var(--pad-m);
    height: var(--pad-m);
    background-color: var(--t-color-lightest);
    border: 2px solid;
    
    &:after {
      top: 4px;
      left: 4px;
      width: var(--pad-t);
      height: var(--pad-t);
    }
  }
}

/*
 ANT MODAL
 */
.ant-modal {
  padding: 0;
  
  .ant-modal-content {
    height: 100%;
    background-color: var(--t-color-lightest);
    border-radius: var(--border-radius-lg);
    
    .ant-modal-close-x {
      svg {
        width: 16px;
        height: 16px;
        color: var(--t-color-gray-1);
      }
    }
    
    .ant-modal-body {
      @extend .child-center;
      position: relative;
      width: 100%;
      padding: 0;
      
      .layout-left, .layout-right {
        .content-main {
          width: 100%;
        }
        
        vertical-align: center;
        height: 100%;
        padding: 56px var(--pad-xh);
        margin: auto 0;
        @include sm {
          padding: 56px var(--pad-s);
        }
        @include md {
          padding: 56px var(--pad-h);
        }
      }
      
      .layout-left {
        width: 50%;
        background-color: var(--t-color-accent);
        border-radius: var(--pad-xt) 0 0 var(--pad-xt);
        text-align: center;
        @include md {
          width: 40%;
        }
        @include sm {
          display: none !important;
        }
      }
      
      .layout-right {
        width: 50%;
        @include md {
          width: 60%;
        }
        @include sm {
          width: 100%;
        }
        
        .ant-divider {
          @extend .text-m;
          @extend .bold;
          display: flex !important;
          align-items: center;
          color: var(--t-color-gray-4) !important;
          text-align: center;
          
          &::before, &::after {
            height: 1px;
            background-color: var(--t-color-gray-4);
            border: none;
          }
          
          .ant-divider-inner-text {
            text-transform: uppercase;
          }
        }
      }
      
      .ant-modal-confirm-body-wrapper {
        width: 100%;
        padding: var(--pad-xH);
        
        .ant-modal-confirm-body {
          .anticon.anticon-exclamation-circle {
            color: var(--t-color-secondary);
          }
        }
      }
    }
  }
}

/*
 ANT DIVIDER
 */
div[class$="-divider"], div[class*="-divider "] {
  margin: var(--pad-lg) 0;
  background-color: var(--t-color-shadow-light);
  @include md {
    margin: var(--pad-md) 0;
  }
  @include sm {
    margin: var(--pad-sm) 0;
  }
}

/*
 ANT FORM
 */
.ant-form-item {
  margin-bottom: var(--pad-l);
  
  .ant-form-item-label {
    @extend .text-m;
    @extend .bold;
    height: 20px;
    padding: 0;
    text-transform: capitalize;
    line-height: 20px;
  }
  
  .ant-form-item-control {
    .ant-form-item-control-input {
      .ant-form-item-control-input-content {
        height: var(--pad-xl);
        
        input {
          height: var(--pad-xl);
        }
      }
      
      .ant-input-affix-wrapper {
        height: var(--pad-xl);
        
        .ant-input {
          height: 22px;
        }
      }
    }
  }
}

/*
 ANT TABS
 */
.ant-tabs.ant-tabs-top {
  .ant-tabs-nav {
    .ant-tabs-nav-operations {
      display: none;
    }
    
    margin-bottom: 4px;
    
    &:before {
      bottom: -4px;
      height: 4px;
      background-color: var(--t-color-gray-5);
      border: none;
    }
    
    .ant-tabs-nav-wrap {
      overflow: visible;
      
      .ant-tabs-nav-list {
        @include sm {
          width: 100%;
        }
        
        .ant-tabs-tab {
          height: var(--pad-xl);
          padding: 4px 0 4px 0;
          margin-right: 0;
          @include sm {
            width: 100%;
            height: min-content;
          }
          
          .ant-tabs-tab-btn {
            @extend .text-s;
            @extend .bold;
            color: var(--t-color-gray-4);
            @include sm {
              width: 100%;
            }
            
            .tab-label {
              display: flex;
              padding: 0 var(--pad-m);
              text-transform: capitalize;
              @include sm {
                @extend .child-center;
                flex-direction: column;
                width: 100%;
              }
              
              .tab-badge, .anticon.anticon-loading {
                width: 24px;
                height: 24px;
                margin: 0 0 0 var(--pad-t);
                background-color: var(--color-red);
                border-radius: 50%;
                color: white;
                text-align: center;
                
                span {
                  display: block;
                  color: white;
                }
              }
              
              .anticon.anticon-loading {
                @extend .child-center;
              }
            }
          }
        }
        
        .ant-tabs-ink-bar {
          bottom: -4px;
          height: 4px;
          background-color: var(--t-color-secondary);
          overflow: visible;
        }
      }
    }
  }
}

/*
 ANT DROPDOWN
 */
.ant-picker-dropdown.ant-picker-dropdown-placement-bottomLeft {
  .ant-picker-panel-container {
    .ant-picker-panel {
      .ant-picker-datetime-panel {
        @extend .text-m;
        
        .ant-picker-date-panel {
          .ant-picker-body {
            .ant-picker-content {
              tbody {
                tr {
                  td.ant-picker-cell.ant-picker-cell-selected {
                    background-color: var(--t-color-primary);
                    border-radius: var(--border-radius-md);
                    
                    .ant-picker-cell-inner {
                      background-color: var(--t-color-primary);
                      color: var(--t-color-lightest);
                    }
                  }
                  
                  td.ant-picker-cell.ant-picker-cell-today {
                    .ant-picker-cell-inner:before {
                      border-color: var(--t-color-secondary);
                    }
                  }
                }
              }
            }
          }
        }
        
        .ant-picker-time-panel {
          .ant-picker-content {
            .ant-picker-time-panel-column {
              @extend .with-scroll;
              
              .ant-picker-time-panel-cell.ant-picker-time-panel-cell-selected {
                .ant-picker-time-panel-cell-inner {
                  background-color: var(--t-color-primary);
                  border-radius: var(--border-radius-md);
                  color: var(--t-color-lightest);
                }
              }
            }
          }
        }
      }
    }
  }
}

/*
 ANT SELECTOR
 */
div[class$="-select"], div[class*="-select "] {
  height: var(--pad-xl);
  
  &[class$="-select-multiple"], &[class*="-select-multiple "] {
    height: auto;
    
    > div {
      background-color: var(--t-color-shadow-lightest) !important;
    }
  }
  
  div[class$="-select-selector"], div[class*="-select-selector "] {
    border-radius: var(--border-radius-md) !important;
    @include sm {
      border-radius: var(--border-radius-sm) !important;
    }
    border: 1px solid var(--t-color-shadow) !important;
    
    &:hover, &:focus {
      border: 1px solid var(--t-color-shadow-dark) !important;
      @extend .shadow;
    }
  }
}

div[class$="-select-dropdown"], div[class*="-select-dropdown "] { //
  padding: 0;
  border: 1px solid;
  border-color: var(--t-color-gray-4);
  border-radius: var(--border-radius-md);
  @include sm {
    border-radius: var(--border-radius-sm);
  }
  
  .rc-virtual-list .rc-virtual-list-holder {
    @extend .with-scroll;
    
    .rc-virtual-list-holder-inner [class$="-select-item-option-active"] {
      color: white;
    }
  }
}

/*
 ANT BREADCRUMB
 */
.ant-breadcrumb > {
  span {
    span.ant-breadcrumb-link {
      @extend .text-s;
      color: var(--t-color-gray-3);
    }
    
    &:hover {
      span.ant-breadcrumb-link {
        a {
          text-decoration: underline;
        }
      }
    }
    
    span.ant-breadcrumb-separator {
      color: var(--t-color-gray-3);
      
      > svg {
        height: 6px;
        margin: 2px 0;
        transform: rotate(-90deg);
      }
    }
  }
  
  span:last-child {
    span.ant-breadcrumb-link {
      &, a {
        @extend .text-m;
        @extend .semi-bold;
        color: var(--t-color-primary);
        text-decoration: none;
      }
    }
  }
}

/*
 SWITCH
 */
button[class$="-switch"], button[class*="-switch "] {
  height: var(--pad-m);
  background-color: var(--t-color-gray-5);
  border: none;
  
  [class$="-switch-handle"], [class*="-switch-handle "] {
    height: var(--pad-s);
  }
}

button[class$="-switch-checked"], button[class*="-switch-checked "] {
  background-color: var(--t-color-primary);
}

/*
 TEXTAREA
 */
textarea {
  @extend .with-scroll;
  background-color: var(--t-color-shadow-lightest) !important;
  border: 1px solid var(--t-color-shadow);
  border-radius: var(--border-radius-md);
  padding: var(--pad-xt) var(--pad-t);
  height: 100% !important;
  min-height: unset !important;
  width: 100%;
  
  @include sm {
    border-radius: var(--border-radius-sm);
  }
  
  &:hover, &:focus {
    border: 1px solid var(--t-color-shadow-dark);
    @extend .shadow;
  }
}

/*
 ANT-TABLE-FILTER-DROPDOWN
 */
div[class$="-dropdown"], div[class*="-dropdown "] {
  //.ant-dropdown-placement-bottomRight,
  //.ant-dropdown.ant-dropdown-placement-topRight,
  //.ant-dropdown.ant-dropdown-placement-bottomCenter
  &[class$="-dropdown-show-arrow"], &[class*="-dropdown-show-arrow "] {
    .layout-dropdown-body {
      @extend .shadow;
      padding: 0;
      background-color: var(--t-color-lightest);
      border-radius: var(--border-radius-md);
      
      [class$="-dropdown-menu-item"], [class*="-dropdown-menu-item"] {
        padding: var(--pad-s) var(--pad-m);
        text-transform: capitalize;
        transition: none;
        
        > * {
          transition: none;
        }
        
        &:first-child {
          border-radius: 4px 4px 0 0;
        }
        
        &:last-child {
          border-radius: 0 0 4px 4px;
        }
        
        &:hover {
          background-color: var(--t-color-primary);
          color: var(--t-color-gray-6);
          font-weight: bold;
          
          a {
            color: var(--t-color-gray-6);
          }
        }
      }
      
      div {
        margin-bottom: var(--pad-s);
      }
      
      div:last-child {
        margin-bottom: 0;
      }
      
      :not(:last-child) {
        border-bottom: 1px solid;
        border-color: var(--t-color-gray-5);
      }
    }
  }
  
  .ant-table-filter-dropdown {
    border-radius: var(--border-radius-lg);
    
    ul.ant-dropdown-menu.ant-dropdown-menu-without-submenu.ant-dropdown-menu-light.ant-dropdown-menu-root.ant-dropdown-menu-vertical {
      @extend .with-scroll;
      
      li.ant-dropdown-menu-item {
        @extend .text-s;
        padding: var(--pad-xt) var(--pad-xs);
        
        &.ant-dropdown-menu-item-selected {
          @extend .text-s;
          @extend .bold;
          background-color: var(--t-color-lightest);
          color: var(--t-color-primary-light);
        }
        
        > span {
          text-transform: capitalize;
        }
      }
    }
    
    .ant-table-filter-dropdown-btns {
      padding: var(--pad-xt);
      
      button:first-child {
        margin-right: var(--pad-t);
        color: var(--color-primary);
        
        &:hover {
          color: var(--color-primary-light);
        }
      }
      
      button:last-child {
        background-color: var(--color-primary);
        border-color: var(--color-primary);
        
        &:hover {
          background-color: var(--color-primary-light);
          border-color: var(--color-primary-light);
        }
      }
    }
  }
}

/*
 ANT-TOOLTIP
 */
.ant-tooltip.ant-tooltip-placement-top {
  > .ant-tooltip-content {
    > .ant-tooltip-arrow {
      width: 20px;
      height: 20px;
      
      > .ant-tooltip-arrow-content {
        width: 20px;
        height: 20px;
        background-color: var(--t-color-primary);
        border-radius: 4px;
      }
    }
    
    > .ant-tooltip-inner {
      @extend .text-s;
      background-color: var(--t-color-primary);
      border-radius: var(--border-radius-lg);
      text-transform: uppercase;
    }
  }
}

/******************************************************** CHECK BOX */
label.ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
  
  .ant-checkbox {
    box-sizing: border-box;
    border-radius: 4px;
    color: var(--t-color-gray-2);
  }
  
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--t-color-primary-light);
    border-color: var(--t-color-primary-light);
    border-radius: 4px;
    transition: none;
  }
}

.juki-card {
  padding: var(--pad-lg);
  background-color: var(--t-color-lightest);
  border-radius: var(--border-radius-lg);
  @include md {
    padding: var(--pad-md);
    border-radius: var(--border-radius-md);
  }
  @include sm {
    padding: var(--pad-sm);
    border-radius: var(--border-radius-sm);
  }
}
