@import "src/styles";

.juki-layout-app .juki-layout-app-main .juki-layout-sider .juki-layout-sider-side-main {
  .juki-layout-main.problems .juki-layout-main-space {
    .content {
      row-gap: var(--pad-xl);
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      column-gap: var(--pad-xl);

      .layout-card {
        width: calc(var(--pad-xl) * 8);
        height: min-content;

        .content-head {
          .problem-status {
            justify-content: space-between;
            width: 100%;

            .problem-index {
              border-radius: var(--border-radius-md);
              position: relative;
              padding: 3px 10px;
              background-color: var(--t-color-gray-5);

              &.accepted, &.wrong {
                .anticon {
                  border-radius: 50%;
                  position: absolute;
                  top: 0;
                  right: -10px;
                  padding: 2px;
                  background-color: var(--color-green-dark);
                  border: 2px solid;
                  border-color: var(--t-color-lightest);
                  color: var(--t-color-lightest);

                  svg {
                    width: 12px;
                    height: 12px;
                  }
                }
              }

              &.wrong {
                .anticon {
                  background-color: var(--color-red);
                }
              }
            }

            .problem-id {
              color: var(--t-color-gray-3);
            }
          }
        }

        .content-title {
          height: 72px;

          .text-m.bold {
            text-align: center;
          }
        }

        .content-title {
          margin: 0;
        }

        .content-info {
          margin: 0;

          .problem-info {
            .problem-timing {
              margin-bottom: var(--pad-xt);

              .layout-timer-clock {
                border-radius: var(--border-radius-md);
                border: 2px solid var(--color-red-light);

                .label-period {
                  color: var(--color-red);
                }

                .content-stamp {
                  padding: 0 4px;
                }
              }

              .closed {
                border-radius: var(--border-radius-md);
                width: min-content;
                padding: 0 var(--pad-xt);
                background-color: var(--color-red);
                color: white;
                text-transform: uppercase;
              }
            }

            .problem-info-data {
              .problem-score .problem-score-points, .problem-success .problem-success-points {
                color: var(--color-info-dark);
              }

              .problem-score .problem-score-label, .problem-success .problem-success-label {
                color: var(--t-color-gray-3);
                text-transform: uppercase;
              }

              .problem-score, .problem-success {
                flex-direction: column;
                width: 50%;
              }

              .divider {
                color: var(--t-color-gray-4);
              }
            }
          }
        }
      }
    }
  }
}
