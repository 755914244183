/*
 MENU-LEFT-BAR
 */
.layout-side-bar.close > .content-layout-side-bar > .layout-main > .content-layout-main > .juki-menu-side-bar {
  > a > .item-sub-menu {
    .anticon {
      padding: 0 var(--pad-t);
    }

    svg {
      width: var(--pad-m);
      height: var(--pad-m);
      margin: 0 auto;
    }

    .item-label {
      display: none;
    }
  }
}