@import "../../../commons/styles/index";

.layout-main.page-profile.submissions {
  .content-layout-main {
    height: 100%;

    .content-main {
      height: calc(100% - 60px);

      .content-main-profile-submissions {
        height: calc(100% - var(--pad-h) - var(--pad-m));
      }

      .layout-pagination {
        margin: var(--pad-m) 0 0 0;
      }
    }
  }
}
