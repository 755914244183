/* ********************************             COLORS             ******************************** */
:root {
  --color-primary-dark: #081826;
  --color-primary: #164066;
  --color-primary-light: #2468A6;
  --color-accent: #F0E04A; // TODO: review this color wht design
  --color-secondary-dark: #C79A3A;
  --color-secondary: #EDB745;
  --color-secondary-light: #FED171;
  --color-lightest: #FFFFFF;
  --color-gray-1: #333333; // gray20 (Safe Hex3)
  --color-gray-2: #4F4F4F; // gray31
  --color-gray-3: #828282; // gray51
  --color-gray-4: #BDBDBD; // gray74
  --color-gray-5: #E0E0E0; // gray88
  --color-gray-6: #F2F2F2; // gray95
  --color-shadow-lightest: rgba(0, 0, 0, 0.05);
  --color-shadow-light: rgba(0, 0, 0, 0.10);
  --color-shadow: rgba(0, 0, 0, 0.25);
  --color-shadow-dark: rgba(0, 0, 0, 0.40);
  --box-shadow-lg: 0 0 20px -6px rgba(0, 0, 0, 0.25);
  --box-shadow-md: 0 0 16px -4px rgba(0, 0, 0, 0.25);
  --box-shadow-sm: 0 0 12px -2px rgba(0, 0, 0, 0.25);
  --color-footer-app: #164066;
}

.theme-light {
  --t-color-primary-dark: #081826;
  --t-color-primary: #164066;
  --t-color-primary-light: #2468A6;
  --t-color-accent: #F0E04A; // TODO: review this color wht design
  --t-color-secondary-dark: #C79A3A;
  --t-color-secondary: #EDB745;
  --t-color-secondary-light: #FED171;
  --t-color-lightest: #FFFFFF;
  --t-color-gray-1: #333333; // gray20 (Safe Hex3)
  --t-color-gray-2: #4F4F4F; // gray31
  --t-color-gray-3: #828282; // gray51
  --t-color-gray-4: #BDBDBD; // gray74
  --t-color-gray-5: #E0E0E0; // gray88
  --t-color-gray-6: #F2F2F2; // gray95
  --t-color-shadow-lightest: rgba(0, 0, 0, 0.05);
  --t-color-shadow-light: rgba(0, 0, 0, 0.10);
  --t-color-shadow: rgba(0, 0, 0, 0.25);
  --t-color-shadow-dark: rgba(0, 0, 0, 0.40);
  --t-box-shadow-lg: 0 0 20px -6px rgba(0, 0, 0, 0.25);
  --t-box-shadow-md: 0 0 16px -4px rgba(0, 0, 0, 0.25);
  --t-box-shadow-sm: 0 0 12px -2px rgba(0, 0, 0, 0.25);
  --t-color-footer-app: #164066;
}

.theme-dark {
  --t-color-primary-dark: #081826;
  --t-color-primary: #2C7FC9;
  --t-color-primary-light: #2C7FC9;
  --t-color-accent: #F0E04A; // TODO: review this color wht design
  --t-color-secondary-dark: #828282; // gray74
  --t-color-secondary: #CCCCCC; // gray80color-secondary: #C79A3A;
  --t-color-secondary-light: #FED171;
  --t-color-lightest: #262626; // gray15
  --t-color-gray-1: #F5F5F5; // whitesmoke (SVG)
  --t-color-gray-2: #F2F2F2; // gray95
  --t-color-gray-3: #E0E0E0; // gray88
  --t-color-gray-4: #828282;
  --t-color-gray-5: #4F4F4F;
  --t-color-gray-6: #333333;
  --t-color-shadow-lightest: rgba(255, 255, 255, 0.15);
  --t-color-shadow-light: rgba(255, 255, 255, 0.90);
  --t-color-shadow: rgba(255, 255, 255, 0.15);
  --t-color-shadow-dark: rgba(255, 255, 255, 0.60);
  --t-box-shadow-lg: 0 0 20 -6px rgba(255, 255, 255, 0.15);
  --t-box-shadow-md: 0 0 16 -4px rgba(255, 255, 255, 0.15);
  --t-box-shadow-sm: 0 0 12 -2px rgba(255, 255, 255, 0.15);
  --t-color-footer-app: #F5F5F5;
}
