@import "../../../../commons/styles/index";

.settings-groups {
  > div {
    display: flex;

    > div {
      &:not(:first-child) {
        width: 50px;
        text-align: center;
      }
    }
  }
}

.observation {
  color: var(--t-color-shadow-dark);
}
