@import "../../../commons/styles/index";

.title-style {
  height: var(--height-layout-title);
  background-color: var(--t-color-lightest);
  border-bottom: 1px solid var(--t-color-gray-5);
}

.juki-layout-app {
  &.fixed-height .juki-layout-app-main{
    height: calc(100vh - var(--height-layout-title));
  }

  .juki-layout-app-main {
    display: flex;
    flex-direction: column;
    height: calc(100% - var(--height-app-header));
    min-height: calc(100vh - var(--height-app-header));
    margin-top: var(--height-app-header);
    margin-bottom: auto;
    @include sm {
      height: calc(100% - var(--height-app-header) - var(--height-app-header-sm));
      min-height: calc(100vh - var(--height-app-header) - var(--height-app-header-sm));
      margin-bottom: var(--height-app-footer-sm);
    }

    .juki-layout-title {
      @extend .title-style;
    }

    .juki-layout-main {
      height: calc(100% - var(--height-layout-title) - var(--height-app-footer));

      .juki-layout-main-space {
        width: 100%;
        height: calc(100% - var(--pad-xl) * 2);
        margin: var(--pad-lg) 0 var(--pad-lg) 0;
        @include md {
          margin: var(--pad-md) 0 var(--pad-md) 0;
          height: calc(100% - var(--pad-md) * 2);
        }
        @include sm {
          margin: var(--pad-sm) 0 var(--pad-sm) 0;
          height: calc(100% - var(--pad-sm) * 2);
        }
      }
    }

    .juki-layout-title, .juki-layout-main {
      width: 100%;
      padding: 0 var(--width-app-padding);

      &.full-width {
        padding: 0 var(--width-app-padding-md);
      }

      @include md {
        padding: 0 var(--width-app-padding-md);
      }
      @include sm {
        padding: 0 var(--width-app-padding-sm);
      }

      .juki-layout-title-space {
        width: 100%;
        height: 100%;
      }
    }

    .juki-layout-sider {
      position: relative;
      .juki-layout-sider-side-bar {
        width: var(--width-layout-side-bar) !important;
        max-width: var(--width-layout-side-bar) !important;
        min-width: var(--width-layout-side-bar) !important;
        background-color: transparent;
        border-right: 1px solid var(--t-color-gray-5);
        transition: none;
        overflow: hidden auto;

        > div:first-child { // ant-layout-sider-children
          height: auto;
          .juki-layout-title {
            padding: 0;
          }
          .juki-layout-main {
            padding: var(--pad-lg) var(--pad-m);
            @include md {
              padding: var(--pad-md) var(--pad-m);
            }
            @include sm {
              padding: var(--pad-sm) var(--pad-m);
            }
          }
          .juki-layout-main .juki-layout-main-space {
            margin: 0;
          }
        }
        display: flex;
        flex-direction: column;
        padding: 0;
        > div:last-child { // ant-layout-sider-trigger
          position: unset;
          flex: 1;
          bottom: 0;
          display: block;
          transition: none;
          width: var(--width-layout-side-bar) !important;
          background-color: transparent;
          color: inherit;
          overflow: hidden;
          span {
            height: 100%;
            svg {
              height: 100%;
              width: var(--pad-md);
            }
          }
        }

        &.close {
          width: var(--width-layout-side-bar-close) !important;
          max-width: var(--width-layout-side-bar-close) !important;
          min-width: var(--width-layout-side-bar-close) !important;

          > div:first-child { // ant-layout-sider-children
            .juki-layout-main {
              padding: var(--pad-m) 0;
            }
          }

          > div:last-child { // ant-layout-sider-trigger
            width: var(--width-layout-side-bar-close) !important;
          }
        }
      }
      .juki-layout-sider-side-main {
        .juki-layout-main, .juki-layout-title {
          padding: 0 var(--pad-lg);
          @include md {
            padding: 0 var(--pad-md);
          }
          @include sm {
            padding: 0 var(--pad-sm);
          }
        }
        .juki-layout-main {
          height: calc(100% - var(--height-layout-title));
        }
      }
    }
  }
}
