@import "break-points";

/*
 JUKI CARD
 */
.juki-card {
  padding: var(--pad-lg);
  background-color: var(--t-color-lightest);
  border-radius: var(--border-radius-lg);
  @include md {
    padding: var(--pad-md);
    border-radius: var(--border-radius-md);
  }
  @include sm {
    padding: var(--pad-sm);
    border-radius: var(--border-radius-sm);
  }
}

/*
 CHILD CENTER
 */
@mixin child-center {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

%child-center {
  @include child-center;
}

.child-center {
  @extend %child-center;
}

/*
 SCROLL
 */
.with-scroll {
  overflow: auto;
  
  &-outside {
    width: calc(100% + var(--pad-xt));
    padding-right: var(--pad-xt);
    overflow: auto;
  }
}

*::-webkit-scrollbar {
  width: var(--pad-xt);
  height: var(--pad-xt);
}

*::-webkit-scrollbar-thumb {
  border-radius: var(--border-radius-lg);
  background: var(--t-color-shadow);
}

/*
 SHADOW
 */
.shadow {
  box-shadow: var(--t-box-shadow-lg);
  @include md {
    box-shadow: var(--t-box-shadow-md);
  }
  @include sm {
    box-shadow: var(--t-box-shadow-sm);
  }
}

/*
 JUKI FIELD EDITABLE
 */
.juki-field-editable {
  display: inline-block;
  
  input {
    border-radius: var(--border-radius-md);
    @include sm {
      border-radius: var(--border-radius-sm);
    }
    padding: 0 var(--pad-t);
    border: 1px solid var(--t-color-shadow);
    outline: none;
    text-align: center;
    font-family: monospace;
    background-color: var(--t-color-shadow-lightest) !important;
  }
  
  input:hover, input:focus, &.editable input {
    border: 1px solid;
    border-color: var(--t-color-shadow-dark);
    @extend .shadow;
  }
}

.juki-field-editable,
[class^="text-xl"] .juki-field-editable,
[class^="text-l"] .juki-field-editable,
[class^="text-m"] .juki-field-editable,
[class^="text-s"] .juki-field-editable,
[class^="text-xs"] .juki-field-editable,
[class^="text-t"] .juki-field-editable,
[class^="text-xt"] .juki-field-editable {
  height: var(--pad-m);
  
  input {
    line-height: var(--pad-s);
  }
}

[class^="text-xh"] .juki-field-editable, [class^="text-h"] .juki-field-editable {
  height: var(--pad-xh);
  
  input {
    line-height: var(--pad-h);
  }
}
