@import "../../../../commons/styles/index";

.switch-theme-selector {
  height: var(--pad-m);
  background-color: var(--t-color-gray-1) !important;
  text-transform: uppercase;

  .ant-switch-inner {
    @extend .text-xs;
    @extend .bold;
    color: var(--t-color-gray-6);
  }
}
