@import "../../../../commons/styles/index";

.content-side-right-bar-top {
  button, .registered, .judge-admin {
    border-radius: var(--border-radius-lg) var(--border-radius-lg) 0 0;
    @include md {
      border-radius: var(--border-radius-md) var(--border-radius-md) 0 0;
    }
    @include sm {
      border-radius: var(--border-radius-sm) var(--border-radius-sm) 0 0;
    }
  }
  .registered, .judge-admin {
    min-height: var(--pad-xl);
    background-color: var(--t-color-primary);
    color: white;
    text-align: center;
    text-transform: uppercase;
    line-height: 32px;
  }
}

.content-side-right-bar-bottom {
  border-radius: 0 0 var(--border-radius-lg) var(--border-radius-lg);
  @include md {
    border-radius: 0 0 var(--border-radius-md) var(--border-radius-md);
  }
  @include sm {
    border-radius: 0 0 var(--border-radius-sm) var(--border-radius-sm);
  }

  > div {
    padding: var(--pad-xt) 0;

    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }
    > {
      div + div {
        padding-top: var(--pad-xt);
      }
    }
    &.content-side-right-bar-bottom-bottom {
      padding: 0;
    }

    p {
      text-align: center;
    }

    > p:first-child {
      color: var(--t-color-gray-3);
      text-align: center;
      text-transform: uppercase;
    }
  }

  > {
    div + div {
      border-top: 1px solid var(--t-color-shadow);
    }
  }
}

.modal-un-frozen-confirmation {
  .ant-modal-content {
    .ant-modal-body {
      flex-direction: column;
      height: 192px;
      padding: var(--pad-l) 0 !important;

      p::first-letter {
        text-transform: uppercase;
      }
    }
  }
}
