.content-problem-statement{
  > div {
    margin-top: var(--pad-s) !important;
  }
}

.problem-samples-box {
  flex-direction: column;

  .problem-sample-box {
    display: flex;
    width: 100%;
    margin-bottom: var(--pad-s);

    .anticon {
      svg {
        width: var(--pad-m);
        height: var(--pad-m);
      }
    }

    .problem-sample-input-box, .problem-sample-output-box {
      display: flex;
      flex: 1;

      > div {
        border-radius: var(--border-radius-md);
        width: calc(100% - var(--pad-xh));
        padding: var(--pad-xt);
        border: 1px solid;
        border-color: var(--t-color-shadow);

        > span {
          background-color: var(--t-color-shadow-lightest) !important;
          white-space: pre;
          font-family: monospace;
        }
      }

      > span.anticon {
        padding: var(--pad-xt);
      }
    }

    .problem-sample-input-box {
      margin-right: var(--pad-s);
    }

    .problem-sample-actions-box {
      width: var(--pad-xl);
      flex-direction: column;
    }
  }
}