@import "variables";
/* ********************************          BREAK POINTS          ******************************** */
// sync with variables
$screen-sm-min: 320px; /* Phones [320, 768] */
$screen-md-min: 768px; /* Tablets [768, 1120] */
$screen-lg-min: 1248px; /* PC [1248, INF] 1120px + var(--pad-xH) * 2 */

// We can't use css variables in a media query

@mixin sm { // Small devices [0, 768px]
  @media (max-width: $screen-md-min) {
    @content;
  }
}

@mixin md { // Medium devices [768px, 1280px]
  @media (min-width: $screen-md-min) and (max-width: calc(#{$screen-lg-min} - 1px)) {
    @content;
  }
}

@mixin lg { // Large devices [1280px, INF]
  @media (min-width: $screen-lg-min) {
    @content;
  }
}
