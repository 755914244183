@import "../../styles/index";

.ant-modal.modal-sign-up {
  width: 950px !important;
  @include sm {
    width: calc(#{$screen-sm-min} - var(--pad-xt) - var(--pad-xt)) !important;
  }
  @include md {
    width: calc(#{$screen-md-min} - var(--pad-xh) - var(--pad-xh)) !important;
  }
  
  .ant-modal-content {
    $height-content-sign-up: 601px;
    //$height-content-sign-up: 496px;
    $height-content-sign-up-mobile: 685px;
    //$height-content-sign-up-mobile: 580px;
    
    .ant-modal-body {
      height: calc(#{$height-content-sign-up} + var(--pad-L) + var(--pad-L));
      @include md {
        height: calc(#{$height-content-sign-up} + var(--pad-L) + var(--pad-L));
      }
      @include sm {
        height: calc(#{$height-content-sign-up-mobile} + var(--pad-L) + var(--pad-L));
      }
      
      .layout-left {
        p::first-letter {
          text-transform: uppercase;
        }
        
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
      
      .layout-right {
        .ant-form {
          .ant-form-item {
            &.half {
              width: calc(calc(100% - 10px) / 2);
              @include sm {
                display: block;
                width: 100%;
              }
            }
            
            &.half:first-child {
              margin-right: 10px;
              @include sm {
                margin-right: 0;
              }
            }
            
            .ant-form-item-control-input-content {
              .password-restriction {
                position: absolute;
                top: -22px;
                right: 0;
                color: var(--t-color-gray-4);
              }
              
              .checkbox-label {
                margin-right: 10px;
              }
            }
            
            &.sign-up-content {
              float: right;
              margin-bottom: 0;
            }
          }
          
          .validator-error {
            color: var(--color-red);
            font-size: 12px;
            line-height: 14px;
            
            &::first-letter {
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }
}
