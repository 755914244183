@import "src/styles";

.layout-settings {
  margin: 0 auto;
  @include lg {
    width: calc((100% / 35) * 23);
  }
  
  .col-edit-image {
    flex-direction: column;
    width: 100% !important;
    
    .row-edit-image-profile {
      position: relative;
      
      .img-style {
        width: 120px;
        border-radius: 100%;
      }
      
      button.ant-btn-circle {
        border-radius: 50%;
        position: absolute;
        right: -16px;
        bottom: 0;
        width: 42px;
        height: 42px;
        box-shadow: var(--box-shadow-lg);
      }
    }
    
    .upload-text {
      color: var(--t-color-gray-4);
      
      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
  
  > .ant-form.ant-form-vertical {
    > .ant-row {
      > .ant-col + .ant-col {
        margin-left: 6%;
      }
      
      > .ant-col {
        width: 47%;
        
        .ant-form-item-control-input-content {
          text-align: center;
        }
        
        .ant-row.ant-form-item.change-password-button {
          position: absolute;
          bottom: 0;
          width: 100%;
        }
      }
    }
  }
}

.settings-update-image-modal {
  width: 100%;
  padding: var(--pad-m);
  
  .preview-box {
    img {
      border-radius: 50%;
    }
  }
}
