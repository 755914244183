@import "src/styles";

.juki-layout-app.problem.view.editor .juki-layout-app-main > .juki-layout-main {
  height: calc(100vh - var(--height-app-header) - var(--height-layout-title) - var(--height-app-footer));
  @include sm {
    height: 500px;
  }
}

.layout-code-editor {
  width: 100%;
  height: 100%;
  padding: var(--pad-m);
  background-color: var(--t-color-lightest);
  
  .content-monaco-editor {
    // 32px (height of content-language-selector) - 32px (height of content-actions)
    height: calc(100% - 32px - 32px);
    padding: var(--pad-xt) 0;
    
    .react-monaco-editor-container {
      width: 100%;
      height: 100%;
      
      .monaco-editor.no-user-select.showUnused {
        width: 100% !important;
        height: 100% !important;
        
        .overflow-guard {
          width: 100% !important;
          height: 100% !important;
        }
      }
    }
  }
  
  .content-actions {
    display: flex;
    justify-content: flex-end;
    
    button {
      margin-left: var(--pad-t);
    }
  }
}
