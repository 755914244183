@import "variables";
/* ********************************             FONT               ******************************** */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700&display=swap");

/* ********************************              TEXT              ******************************** */
// default font-weight: 400;
// default font-family: "Inter", sans-serif;
.normal {
  font-weight: 400;
}

.semi-bold {
  font-weight: 600;
}

.bold {
  font-weight: 800;
}

.text-xh { // extra huge
  font-size: var(--pad-m);
  line-height: var(--pad-xh);
}

.text-h { // huge
  font-size: 22px;
  line-height: var(--pad-xh);
}

.text-xl {
  font-size: var(--pad-s);
  line-height: var(--pad-m);
}

.text-l {
  font-size: 18px;
  line-height: var(--pad-m);
}

.text-m {
  font-size: var(--pad-xs);
  line-height: var(--pad-m);
}

.text-s {
  font-size: 14px;
  line-height: var(--pad-m);
}

.text-xs {
  font-size: var(--pad-t);
  line-height: var(--pad-m);
}

@mixin text-t {
  font-size: 10px;
  line-height: var(--pad-m);
}

%text-t {
  @include text-t;
}

.text-t {
  @extend %text-t;
}

@mixin text-xt {
  font-size: var(--pad-xt);
  line-height: var(--pad-m);
}

%text-xt {
  @include text-xt;
}

.text-xt {
  @extend %text-xt;
}

// overwriting styles
h1, h2, h3, h4, h5, h6 {
  &:first-letter {
    text-transform: capitalize;
  }
  
  color: var(--t-color-primary);
  font-weight: 700;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

h6 {
  font-size: 26px;
  line-height: var(--pad-xh);
}

h5 {
  font-size: var(--pad-l);
  line-height: var(--pad-xh);
}

h4 {
  font-size: 30px;
  line-height: var(--pad-H);
}

h3 {
  font-size: var(--pad-xl);
  line-height: var(--pad-xH);
}

h2 {
  font-size: 34px;
  line-height: 64px;
}

h1 {
  font-size: var(--pad-h);
  line-height: 72px;
}

p {
  @extend .text-m;
  margin: unset;
}

ul {
  margin: unset;
}
