.juki-loader {
  width: 100%;
  height: 100%;
  overflow: hidden;
  
  .juki-spin {
    position: relative;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    align-content: center;
    display: flex;
    max-height: var(--pad-xH);
    max-width: var(--pad-xH);
    
    > span {
      height: 100%;
      width: 100%;
      color: var(--t-color-primary);
      
      svg {
        //margin: auto;
        //width: 100%;
        //height: 100%;
        //max-width: 100%;
        //max-height: 100%;
        width: 100%;
        height: 100%;
      }
    }
  }
}
