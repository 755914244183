@import "../../../../commons/styles/index";

.content-problem-searcher {
  .content-problem-searcher-actions {
    @extend .child-center;
    margin-bottom: var(--pad-t);
  }

  .content-problem-picker {
    display: flex;

    .ant-select.ant-select-single {
      flex: 1;
    }

    > button {
      margin: 0 var(--pad-t);
    }
  }
}

.ant-select.ant-select-single.ant-select-show-arrow {
  .ant-select-selector {
    .ant-select-selection-item {
      display: flex;

      .option-problem-tag {
        margin-left: var(--pad-m);
        color: var(--t-color-gray-3);

        .option-problem-rating {
          margin-left: var(--pad-m);
        }
      }
    }
  }
}

.dropdown-problem-searcher {
  .rc-virtual-list {
    .rc-virtual-list-holder {
      .rc-virtual-list-holder-inner {
        .ant-select-item.ant-select-item-option {
          .ant-select-item-option-content {
            .option-problem-name {
              display: block;
              text-overflow: ellipsis;
              overflow: hidden;
            }

            .option-problem-tag {
              .option-problem-rating {
                float: right;
              }
            }
          }
        }
      }
    }
  }
}
