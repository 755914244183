.juki-menu-side-bar {
  > a > .item-sub-menu {
    display: flex;
    align-items: center;
    height: 48px;
    margin-bottom: var(--pad-xs);
    border-left: 6px solid transparent;
    border-radius: var(--border-radius-md);
    color: var(--t-color-gray-3);
    
    svg {
      width: var(--pad-m);
      height: var(--pad-m);
      min-width: var(--pad-m);
      min-height: var(--pad-m);
      margin: 0 4px 0 6px;
    }

    .item-label {
      &:first-letter {
        text-transform: uppercase;
      }

      margin: 0 0 0 var(--pad-t);
    }

    &:hover {
      background-color: var(--t-color-lightest);
      border-color: var(--t-color-secondary);
    }

    &.item-selected {
      color: var(--t-color-gray-1);
      background-color: var(--t-color-lightest);
      border-color: var(--t-color-secondary);
    }
  }
}