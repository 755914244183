@import "../../../commons/styles/index";

.juki-layout-app.contest.view {
  .juki-layout-sider-side-bar {
    .juki-layout-title .juki-layout-title-space {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      
      .content-status-badge {
        
        .status-badge {
          border-radius: var(--border-radius-md);
          width: max-content;
          height: var(--pad-m);
          padding: 0 var(--pad-t);
          color: white;
          text-transform: uppercase;
          
          &.live {
            background-color: var(--color-red);
          }
          
          &.past {
            background-color: var(--t-color-primary);
          }
          
          &.upcoming {
            background-color: var(--color-green-dark);
          }
        }
      }
    }
    
    &.close {
      .side-bar-title {
        .content-status-badge {
          display: flex;
          flex: 1;
          justify-content: center;
          align-items: center;
          width: 100%;
          border-radius: 0;
          
          .status-badge {
            padding: 0;
          }
        }
        
        .layout-timer-clock {
          flex: 2;
        }
      }
    }
  }
  
  .juki-layout-sider-side-main {
    .juki-layout-title {
      position: relative;
      
      .juki-layout-title-space {
        .content-breadcrumb {
          display: flex;
          justify-content: left;
          align-items: center;
          height: 50px;
          
          span[class*="icon "] {
            margin-right: var(--pad-m);
          }
          
          a {
            color: var(--t-color-gray-1);
            text-transform: capitalize;
          }
        }
        
        .content-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 90px;
        }
      }
      
      &:after {
        position: absolute;
        top: 50px;
        left: 0;
        width: 100%;
        border-top: 1px solid var(--t-color-gray-5);
        content: " ";
      }
    }
    
    .juki-layout-main {
      &.with-pagination {
        .juki-layout-main-space {
          height: calc(100% - var(--pad-xl)) !important;
          margin: var(--pad-xl) 0 0 0 !important;
        }
      }
      
      &.clarifications {
        background-color: var(--t-color-lightest);
        
        .ask-question {
          padding-left: var(--pad-m);
          @include md {
            padding-left: var(--pad-xt);
          }
          @include sm {
            padding-left: var(--pad-xt);
          }
          display: flex;
          padding-bottom: var(--pad-xs);
          margin-bottom: var(--pad-t);
          border-bottom: 1px solid;
          border-color: var(--t-color-gray-5);
          cursor: pointer;
          text-transform: capitalize;
        }
        
        .no-clarification-yet {
          width: 100%;
          padding-top: var(--pad-m);
          padding-left: var(--pad-m);
          text-align: center;
          @include md {
            padding-left: var(--pad-xt);
          }
          @include sm {
            padding-left: var(--pad-xt);
          }
        }
        
        .clarification-card {
          width: 768px;
          @include md {
            width: 512px;
          }
          @include sm {
            width: calc(100% - var(--pad-s));
          }
          position: relative;
          margin: var(--pad-s) auto;
          
          .clarification-head {
            display: flex;
            
            .logo {
              margin-right: var(--pad-s);
              
              img {
                width: var(--pad-h);
                height: var(--pad-h);
                border-radius: 50%;
              }
            }
            
            .data {
              width: 100%;
              
              .user-section {
                color: var(--t-color-gray-3);
              }
              
              .scope-date-section {
                display: flex;
                line-height: var(--pad-xs);
                
                .scope {
                  padding: 0 var(--pad-xt);
                  background-color: var(--t-color-gray-5);
                  border-radius: var(--border-radius-lg);
                  color: var(--t-color-gray-2);
                }
                
                .date {
                  padding: 0 var(--pad-xt);
                  color: var(--t-color-gray-3);
                }
              }
            }
            
            .problem-index {
              position: absolute;
              right: var(--pad-s);
              display: flex;
              
              .label {
                margin-right: var(--pad-xt);
                color: var(--t-color-gray-2);
                text-transform: capitalize;
              }
              
              .index {
                width: min-content;
                padding: 0 var(--pad-xt);
                background-color: var(--t-color-gray-5);
                border-radius: var(--border-radius-lg);
                color: var(--t-color-gray-2);
              }
            }
          }
          
          .clarification-body {
            padding-left: calc(var(--pad-h) + var(--pad-s));
            
            .question {
              padding: var(--pad-t) 0 var(--pad-t) 0;
              color: var(--t-color-gray-2);
            }
            
            .answer {
              word-break: break-word;
              
              .label {
                color: var(--t-color-gray-3);
                text-transform: capitalize;
              }
            }
            
            .edit {
              position: absolute;
              bottom: var(--pad-s);
              left: var(--pad-s);
            }
          }
        }
      }
    }
  }
}
