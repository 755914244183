@import "ant-design-overwrite";
@import "break-points";
@import "fonts";
@import "styles";
@import "themes-and-colors";
@import "variables";

/* ********************************         GLOBAL STYLES          ******************************** */
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-print-color-adjust: exact !important;
  font-weight: 400;
}

@page {
  size: legal landscape;
}

#root {
  width: 100%;
  overflow: auto;
}
