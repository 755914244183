@import "../../commons/styles/index";

.juki-layout-app .juki-layout-app-footer {
  &.hidden {
    display: none;
  }

  margin: auto 0 0 0;
  width: 100%;
  height: var(--height-app-footer);
  padding-left: calc((100% - 1120px) / 2);
  background: var(--t-color-lightest);
  border-top: 2px solid;
  border-color: var(--t-color-gray-5);
  @include md {
    padding: 0 var(--pad-xh);
  }
  @include sm {
    position: fixed;
    bottom: 0;
    height: var(--height-app-footer-mobile);
    padding: 0 var(--pad-xh);
    background: var(--t-color-primary);
  }

  > .content-app-footer {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 1120px;
    height: 100%;
    overflow: hidden;
    @include md {
      width: 100%;
    }
    @include sm {
      width: 100%;
    }

    .ant-menu {
      width: 100%;
      background: inherit;
      border: none;
      @include sm {
        @extend .child-center;
      }

      .ant-menu-item {
        @extend .text-s;
        position: relative;
        top: 0;
        vertical-align: unset;
        width: 150px;
        padding: var(--pad-s) 0;
        margin: 0;
        border: none;
        text-align: left;
        @include md {
          width: 120px;
        }
        @include sm {
          width: calc(100% / 3);
          text-align: center;
        }

        a {
          color: var(--t-color-footer-app);
          text-transform: uppercase;
          @include sm {
            color: white !important;
          }
        }
      }

      .ant-menu-item-selected {
        //font-family: $font-bold;
        font-family: "Inter", sans-serif;
        font-weight: 700;
      }

      .ant-menu-item:hover {
        margin: 0;
        color: white;
      }

      .ant-menu-item-selected:after, .ant-menu-item:hover:after {
        @include sm {
          position: absolute;
          top: 0;
          bottom: 1px;
          left: var(--pad-xt);
          width: calc(100% - var(--pad-xs));
          height: var(--pad-xt);
          background: var(--t-color-secondary);
          border-radius: 0 0 4px 4px;
          content: " ";
        }
      }
    }

    > .content-menu {
      @extend .child-center;
      display: none;
      justify-content: space-between;
      height: 65px;
      @include sm {
        display: initial;
      }
    }

    > .content-logo-menu-social-faq-box {
      @extend .child-center;
      justify-content: space-between;
      height: 72px;
      border-bottom: 1px solid;
      border-color: var(--t-color-gray-5);
      @include sm {
        height: 48px;
        border-top: 1px solid;
        border-bottom: none;
        border-color: var(--t-color-gray-5);
        color: white;
      }

      > .logo-menu {
        @extend .child-center;

        > .logo-hor {
          display: flex;
          justify-content: flex-start;
          height: 100%;
          margin-right: var(--pad-m);
          color: var(--t-color-footer-app);
          @include sm {
            color: white;
          }

          svg {
            width: 110px;
            height: 48px;
            @include sm {
              height: var(--pad-l);
            }
          }

          &:after {
            content: ":LG";
            @include md {
              content: ":MD";
            }
            @include sm {
              content: ":SM";
            }
          }
        }

        > .ant-menu {
          @include sm {
            display: none;
          }
        }
      }

      > .social-faq {
        display: flex;

        > .content-social {
          padding-left: var(--pad-xs);
          margin-left: var(--pad-xs);
          border-left: 1px solid;
          border-color: var(--t-color-gray-4);
          @include sm {
            color: white !important;
          }
        }
      }
    }
  }
}
