@import "../../commons/styles/index";

.layout-main.page-profile {
  padding-top: var(--pad-h);
  padding-bottom: var(--pad-h);

  .content-layout-main {
    height: auto;
    min-height: 100%;
    background-color: var(--t-color-lightest);
    overflow: visible;
    $height-content-tabs: 60px;

    .content-tabs {
      @extend .child-center;
      justify-content: flex-start;
      height: $height-content-tabs;
      padding: 0 calc(100% / 35);
    }

    .content-main {
      min-height: calc(100% - #{$height-content-tabs});
      padding: var(--pad-m);
    }
  }
}
