button {
  &.error {
    background-color: var(--color-red-dark) !important;
    border-color: transparent !important;
    color: white !important;
    pointer-events: none;
  }
  
  &.success {
    background-color: var(--color-green-dark) !important;
    border-color: transparent !important;
    color: white !important;
    pointer-events: none;
  }
  
  &[class$="-btn-loading"], &[class*="-btn-loading "] {
    opacity: 1 !important;
    
    &::before {
      content: unset !important;
    }
  }
}
