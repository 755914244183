.math-markdown-viewer {
  padding: 0;

  p {
    margin: var(--pad-t) 0;
    font-family: inherit;
    font-size: 16px;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: var(--pad-t) 0;
    font-weight: bold;
  }

  h1, h2 {
    line-height: 28px;
  }

  h3, h4 {
    line-height: 24px;
  }

  h1 {
    font-size: 26px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 22px;
  }

  h4 {
    font-size: 20px;
  }

  h5 {
    font-size: 18px;
  }

  h6 {
    font-size: 16px;
  }

  table {
    margin: auto;
    border-collapse: collapse;

    tr:hover {
      background-color: rgba(0, 0, 0, 0.14) !important;
    }

    thead tr {
      background-color: rgba(0, 0, 0, 0.21);
    }

    tr td, tr th {
      padding: 4px 8px;
    }

    tr:nth-child(even) {
      background-color: rgba(0, 0, 0, 0.07);
    }
  }

  blockquote {
    padding-left: var(--pad-s);
    border-left: 4px solid var(--t-color-primary-light);
    margin: var(--pad-t) 0;
    font-weight: lighter;
    text-align: justify;
  }

  ul, ol {
    li::marker {
      color: var(--t-color-primary-light);
    }

    margin: var(--pad-t) 0;
  }

  a {
    &:hover {
      color: var(--t-color-primary-light);
      text-decoration: underline;
    }
  }

  .layout-monaco-editor {
    margin: 10px 0;
  }
}
