@import "../../styles/index";

.layout-md-tex-editable {
  .content-md-tex-editable {
    width: calc((100% / 37) * 23);
  }
  
  &.editable {
    .content-md-tex-editable {
      width: 100%;
      
      .mathMarkDownViewer {
        width: calc((100% / 37) * 17);
      }
      
      textarea.ant-input {
        width: calc((100% / 37) * 17);
      }
    }
  }
}

.juki-layout-md-editor {
  .content-bar-options {
    display: flex;
    height: var(--pad-m);
    background-color: var(--t-color-primary);
    border-radius: var(--border-radius-lg) var(--border-radius-lg) 0 0;
    
    > div {
      flex: 1;
      
      > span {
        display: block;
        padding: 4px;
        margin: auto;
        
        svg {
          color: var(--t-color-lightest);
        }
      }
    }
    
    > div + div {
      border-left: 1px solid var(--t-color-lightest);
    }
  }
  
  .content-editor-preview {
    border: 1px solid var(--t-color-shadow);
    border-radius: var(--border-radius-md);
    @include sm {
      border-radius: var(--border-radius-sm);
    }
    
    .preview {
      > div {
        padding: var(--pad-s);
      }
    }
    
    .editor textarea {
      border: none;
    }
    
    &.editor-expanded {
      .editor {
        width: 100%;
      }
      
      .preview {
        display: none;
      }
    }
    
    &.editor-right-preview-left {
      display: flex;
      
      .editor {
        flex: 1;
        border-right: 2px solid var(--t-color-shadow);
      }
      
      .preview {
        flex: 1;
      }
    }
    
    &.editor-top-preview-bottom {
      .editor {
        border-bottom: 2px solid var(--t-color-shadow);
      }
    }
    
    &.preview-expanded {
      .editor {
        display: none;
      }
      
      .preview {
        width: 100%;
      }
    }
  }
}

.modal-upload-image {
  width: calc(100% - var(--pad-lg)) !important;
  
  input.juki-btn {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
