@import "../../../commons/styles/index";

.language-selector {
  float: left;
  height: var(--height-app-header);
  width: calc(var(--pad-h) * 2);
  justify-content: space-around;
  display: flex;

  img {
    margin: auto;
    height: var(--pad-xs);
    width: var(--pad-m);
    outline: 1px solid var(--color-navbar);
  }

  svg {
    margin: auto;
  }

  .juki-loader {
    width: var(--pad-m);
  }
}

.ant-dropdown.language-selector-dropdown {
  img {
    height: var(--pad-xs);
    width: var(--pad-m);
    outline: 1px solid var(--color-navbar);
  }
}
