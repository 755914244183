@import "../../../../commons/styles/index";

/*.app-main.contest.view.printScore {
  position: absolute;
  width: 100%;
  height: auto;
  min-height: unset;

  .app-content-main {
    height: auto !important;

    .contest-title {
      padding: var(--pad-s);
      @extend .child-center;
      flex-direction: column;

      .contest-total-submissions, .content-total-registered, .content-total-duration, .content-date {
        text-transform: uppercase;
        @extend .text-m;
        @extend .bold;

        span {
          margin-right: var(--pad-xt);
        }

        span.label {
          @extend .text-m;
          @extend .semi-bold;
          margin-right: var(--pad-t);
        }
      }
    }

    .scoreboard-layout {
      height: auto;

      .ant-table-container {
        height: min-content;
        overflow: visible;

        .ant-table-body {
          max-height: unset !important;

          table {
            .user-complete-name {
              .text-xs.semi-bold {
                color: var(--t-color-gray-3);
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}*/

.scoreboard-layout {
  width: 100%;
  height: 100%;

  .frozen-label {
    width: 100%;
    height: var(--pad-xs);
    background-color: var(--color-info);
    border-radius: 8px 8px 0 0;
    color: white;
    text-align: center;
    line-height: var(--pad-xs);
  }

  /*.virtual-grid {
    @extend .with-scroll;

    .virtual-table-cell {
      height: 72px;
    }

    [class="virtual-table-cell-#"] {
      div.cell-body-user-position {
        padding: 21px 8px 21px 16px;

        .text-m.semi-bold {
          border-radius: 50%;
          width: 30px;
          height: 30px;
          padding: 3px 0;
          background-color: var(--color-red-light);
          color: var(--t-color-lightest);
          text-align: center;
        }

        &.no-points {
          .text-m.semi-bold {
            // TODO: CHECK THIS
            background-color: var(--t-color-gray-5) !important;
          }
        }
      }
    }
    .virtual-table-cell-userNickname {
      div.user-name {
        padding: 12px 8px;

        .text-l {
          @extend .child-center;
          height: 48px;
          color: var(--t-color-gray-3);
        }
      }
    }

    .virtual-table-cell-points {
      div.user-points {
        @extend .child-center;
        flex-direction: column;
        padding: 12px 8px;

        .text-s {
          color: var(--t-color-gray-3);
        }
      }
    }

    [class^="virtual-table-cell-problem"] {
      div.user-attempt {
        display: flex;
        justify-content: center !important;
        padding: 12px 8px;

        .balloon {
          width: 20px;
          height: 48px;

          svg {
            width: 20px;
            height: 48px;
          }
        }

        .text-xs {
          @extend .child-center;
          width: min-content;
          color: var(--t-color-gray-3);
        }
      }
    }
  }*/

  .ant-table {
    .ant-table-container {
      .ant-table-body {
        position: relative;

        table {
          border-collapse: separate;
          border-spacing: 0 2px;
          overflow: visible;

          .ant-table-tbody {
            overflow: visible;

            tr[data-row-key$="row-sticky"] {
              td {
                position: sticky !important;
                top: 0;
                bottom: 0;
                z-index: 4;

                > div {
                  border-top: 4px solid var(--t-color-secondary);
                  border-bottom: 4px solid var(--t-color-secondary);
                }
              }

              td.ant-table-cell-fix-left {
                z-index: 5;
              }

              td:first-child > div {
                border: 4px solid var(--t-color-secondary);
                border-right: none;
                border-radius: 8px 0 0 8px;
              }

              td:last-child > div {
                border: 4px solid var(--t-color-secondary);
                border-left: none;
                border-radius: 0 8px 8px 0;
              }
            }

            tr.ant-table-row {
              td {
                > div {
                  height: 60px !important;
                  padding: 6px 4px !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
