@import "../../../../commons/styles/index";
@import "../../../../commons/styles/themes-and-colors";

.ant-modal.modal-change-password {
  width: 320px !important;
  height: 400px;
  @include sm {
    width: calc(100% - var(--pad-xt) - var(--pad-xt)) !important;
  }

  > .ant-modal-content {
    @extend .child-center;
    padding: var(--pad-m);

    .validator-error {
      color: var(--color-red);
      font-size: 12px;
      line-height: 14px;
    }

    > .ant-modal-body > .ant-form.ant-form-vertical > .ant-row.ant-form-item > .ant-col.ant-form-item-control {
      @extend .child-center;
    }
  }
}
