@import "src/styles";

.content-contest-problem-viewer {
  position: relative;
  width: 100%;
  height: 100%;
  $height-content-tab: 52px;
  display: flex;
  flex-direction: column;
  
  .content-tab {
    position: relative;
    left: 0;
    height: calc(#{$height-content-tab});
    padding: 8px var(--pad-l);
    margin-bottom: var(--pad-s);
    background-color: var(--t-color-lightest);
    display: flex;
    justify-content: space-between;
    @include md {
      height: calc(#{$height-content-tab});
      padding: 8px var(--pad-m);
      margin-bottom: var(--pad-t);
    }
    @include sm {
      height: calc(#{$height-content-tab});
      padding: 8px var(--pad-s);
      margin-bottom: var(--pad-xt);
    }
    
    .ant-tabs.ant-tabs-top {
      width: min-content;
    }
    
    a {
      display: block;
    }
  }
  
  .content-main {
    position: relative;
    flex: 1;
    @include md {
      display: flex;
      flex-direction: column;
    }
    @include sm {
      display: flex;
      flex-direction: column;
      overflow: visible;
    }
    
    > .layout-side-main {
      height: auto;
      @include md {
        width: 100%;
        margin-top: var(--pad-t);
      }
      @include sm {
        margin-top: var(--pad-xt);
      }
      
      > .content-layout-side-main {
        @include md {
          width: 100%;
          padding: var(--pad-m);
        }
        @include sm {
          width: 100%;
          padding: var(--pad-m);
        }
      }
    }
    
    > .layout-side-bar {
      height: auto;
      @include md {
        width: 100%;
      }
      
      > .content-layout-side-bar {
        height: auto;
      }
    }
  }
}
